import React from 'react'
import './Footer.css'
import {FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="left">
                <div className="location">
                    <FaSearchLocation size={20} style={{color: '#ffffff', marginRight: '2rem'}}/>
                <div>
                    <p>123 An Naseem St.</p>
                    <h4>Jeddah, SA</h4>
                </div>
                </div>

                <div className="phone">
                    <FaPhone size={20} style={{color: '#ffffff', marginRight: '2rem'}}/>
                    <h4>+966 59 675 5881</h4>
                
                </div>
                <div className="email">
                    <FaMailBulk size={20} style={{color: '#ffffff', marginRight: '2rem'}}/>
                    <h4>talhagrace992@gmail.com</h4>
                </div>
            </div>

            <div className="right">
                <h4>About the company</h4>
                <p>Lorem ipsum dolar sit amet, cinsectetur
                    adispicing elit, sed do eusmod tempor
                    incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                </p>
                <div className="social">
                    <FaFacebook size={30} style={{color: '#ffffff', marginRight: '1rem'}}/>
                    <FaTwitter size={30} style={{color: '#ffffff', marginRight: '1rem'}}/>
                    <FaLinkedin size={30} style={{color: '#ffffff', marginRight: '1rem'}}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer