import React from 'react'
import './Training.css'
import { Link } from 'react-router-dom'
import moon from '../../assets/moon.jpg'
import moon1 from '../../assets/moon1.jpg'

const Training = () => {
  return (
    <div className="taining">
        <div className="left">
            <h1>Training</h1>
            <p>Thorough training is a necessity when traveling to space. We offer all inclusive training for pre-flight and in-flight scenarios.</p>
            <Link to='/contact'><button className='btn'>Contact</button></Link>
        </div>
        <div className="right">
            <div className="img-container">
                <div className="img-stack top">
                    <img src={moon} className='img' alt="" />
                </div>
                <div className="img-stack bottom">
                    <img src={moon1} className='img' alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Training