import React from 'react'

import Footer from '../components/Footer/Footer'
import Form from '../components/Form/Form'
import HeroImage from '../components/HeroImage/HeroImage'
import Navbar from '../components/Navbar/Navbar'

const Contact = () => {
  return (
    <>
    <Navbar/>
    <HeroImage
    heading= 'CONTACT.'
    text= 'Contact GLX Travel'
    />
    <Form/>
    <Footer/>
    </>
  )
}

export default Contact