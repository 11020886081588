import React from 'react'
import Footer from '../components/Footer/Footer'
import HeroImage from '../components/HeroImage/HeroImage'
import Navbar from '../components/Navbar/Navbar'
import Pricing1 from '../components/Pricing/Pricing'

const Pricing = () => {
  return (
    <>
    <Navbar/>
    <HeroImage 
    heading= 'PRICING.'
    text= 'Choose your trip.'
    />
    <Pricing1/>
    <Footer/>
    </>
  )
}

export default Pricing